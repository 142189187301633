function TitleSection({ title, content }) {
  return (
    <div>
      <h2>{title}</h2>
      {content}
    </div>
  );
}

export default TitleSection;
