import { Box, Container, Stack } from '@mui/material';
import conceptDiagram from '../static/images/totem-tower/diagram concept.jpg';
import sitePlan from '../static/images/totem-tower/dwg Ground Floor Plan.jpg';
import section from '../static/images/totem-tower/dwg section.jpg';
import typicalPlan from '../static/images/totem-tower/dwg Typical Floor Plan.jpg';
import mainRender from '../static/images/totem-tower/ext-overall.jpg';
import extPodium from '../static/images/totem-tower/ext podium.jpg';
import historical1 from '../static/images/totem-tower/haida settlement.jpg';
import historical2 from '../static/images/totem-tower/indianvillage.jpg';
import gathering from '../static/images/totem-tower/int gathering v1 copy.jpg';
import livingRoom from '../static/images/totem-tower/livingroom.png';
import wallCloseup from '../static/images/totem-tower/int wall detail v1 copy.jpg';
import modelCloseup from '../static/images/totem-tower/model close up.jpg';
import model from '../static/images/totem-tower/model.png';
import vancouverAerial from '../static/images/totem-tower/vancouver-aerial.jpeg';
import historical3 from '../static/images/totem-tower/woodenobjects.jpg';
import FadePage from '../components/FadePage';
import HalfContainer from '../components/HalfContainer';

export const TotemPage = () => {
  return (
    <FadePage>
      <Container>
        <Stack spacing='3vh' sx={{ marginBottom: '10vh' }}>
          <h1>Totem tower</h1>
          <img src={vancouverAerial} alt='progress-diagram' width='100%' />
          <HalfContainer>
            <div>
              In the recent years, Vancouver has experienced exponential growth
              in population growth and urban development. Similar to many other
              cities that experienced a rapid growth spurt, Vancouver buildings
              opted to use economical and marketable glass-tower typology. The
              end result of globalization is generic skylines with mundane urban
              experiences, concealing its rich cultural history. So, how can
              skyscrapers begin to express, appreciate, and restore the
              surrounding cultural context?
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '5vw 0',
                }}
              >
                <Box
                  sx={{
                    mx: '5rem',
                    background: '#eee',
                    p: '15px',
                    maxWidth: '500px',
                  }}
                >
                  <strong>totem \TOH-tum\ noun.</strong> 1 a : an object (such
                  as an animal or plant) serving as the emblem of a family or
                  clan and often as a reminder of its ancestry; also: a usually
                  carved or painted representation of such an object.
                </Box>
              </div>
              Totems often take on the form of artifacts and art to represent
              spiritual and cultural significance. They are often the tallest
              and most visible structure in the establishment. However, the traditional forms of monuments or emblems are easily
            dwarfed by the glass-and-concrete-based skyscrapers and traffic in
            today’s scale of society and urbanism. 
            </div>
            <Box sx={{ px: '5rem' }}>
              <img src={historical2} alt='progress-diagram' width='100%' />
            </Box>
          </HalfContainer>
          <img src={conceptDiagram} alt='concept-diagram' width='100%' />
          <HalfContainer>
            As an attempt to change the
            monotonous urban characters and mundane sceneries and experiences
            derived from capitalistic globalization, Meme Architects proposes a
            new interpretation of the totem in contemporary urbanism, through
            the manifestation of this unique iconography into the scale of
            architecture - specifically in the form of a highrise tower, one of
            the most visible and iconic man-made structures. In this case, what
            is the cultural heritage we should be advocating in Vancouver?
            <div>
              The city of Vancouver is the traditional settlement of the Pacific
              Northwest Coast people, including the Musqueam, Squamish, and
              Tsleil-Waututh nations. These indigenous communities have a
              long-established history of settlement and control over the lands.
              Over the years, they have developed complex and distinct visual
              languages that fascinate the world to this day.
            </div>
          </HalfContainer>
          <HalfContainer>
            <Box sx={{ px: '5rem' }}>
              <img src={historical1} alt='level-4-plan' width='100%' />
            </Box>
            <Box sx={{ px: '5rem' }}>
              <img src={historical3} alt='progress-diagram' width='100%' />
            </Box>
          </HalfContainer>
          <HalfContainer>
            Despite having these rich cultural resources, the urban environments
            of Vancouver fail to establish a unique identity. Instead, it is
            well known as one of the most ‘generic cities’ to the film industry
            - the kind of city that you can be dressed into look like any city.
            <iframe
              width='560'
              height='315'
              src='https://www.youtube.com/embed/ojm74VGsZBU'
              title='YouTube video player'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen
            ></iframe>
          </HalfContainer>
          <HalfContainer>
            Returning to the initial question. How can skyscrapers begin
            to express, appreciate, and restore the surrounding cultural
            context? Situated in the southern end of downtown Vancouver, the
            Totem Tower is a speculative project that interrogates the
            relationship between urbanism and heritage in a contemporary context
            and in an ever-so-common development scenario.
          </HalfContainer>
          <img src={mainRender} alt='' width='100%' />
          <HalfContainer>
            As Vancouver's latest landmark building, the Totem Tower will be the
            tallest wooden structure in the city's skyline, bringing unique
            iconography to the otherwise ignorant urban landscape.
          </HalfContainer>
          <img src={extPodium} alt='' width='100%' />
          <HalfContainer>
            The tower’s openness to traditional activities is reflected in its
            public programming. The lower floors of the tower are designed to
            welcome and encourage active community engagement while
            accommodating various open activities for the Vancouverites.
          </HalfContainer>
          <img src={gathering} alt='' width='100%' />
          <img src={sitePlan} alt='' width='100%' />
          <HalfContainer>
            Sculptural geometries and artisanal details are hallmarks of the
            Totem Tower experience, reflected in the curvaceous designs of walls
            and ceilings. The spaces and services for the residents are no
            exception.
          </HalfContainer>
          <img src={livingRoom} alt='' width='100%' />
          <img src={wallCloseup} alt='' width='100%' />
          <img src={typicalPlan} alt='' width='100%' />
          <HalfContainer>
            No rocks are unturned when it comes to applying native North
            American aesthetics. The Totem Tower’s spatial compositions -
            visible in plans and sections - are designed and arranged to mimic
            traditional and vernacular artistic approaches of the region.
          </HalfContainer>
          <img src={section} alt='' width='100%' />
          <img src={modelCloseup} alt='' width='100%' />
          <img src={model} alt='' width='100%' />
          <a href='/' style={{ color: 'black' }}>
            Return home
          </a>
        </Stack>
      </Container>
    </FadePage>
  );
};
