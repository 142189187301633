import { Box, Container, Stack, Typography } from '@mui/material';
import basement from '../static/images/musk-residence/basement_v4_web.jpg';
import conference from '../static/images/musk-residence/conference_web.jpg';
import livingroom from '../static/images/musk-residence/livingroom_web.jpg';
import aerial from '../static/images/musk-residence/musk_residence_web.jpg';
import parking from '../static/images/musk-residence/parking_v4_web.jpg';
import diagramGif from '../static/images/musk-residence/diagram.gif';
import plan1 from '../static/images/musk-residence/plan-1.jpg';
import plan2 from '../static/images/musk-residence/plan-2.jpg';
import plan4 from '../static/images/musk-residence/plan-4.jpg';
import sectionConference from '../static/images/musk-residence/section-conference.jpg';
import sectionLiving from '../static/images/musk-residence/section-living-quarter.jpg';
import FadePage from '../components/FadePage';
import HalfContainer from '../components/HalfContainer';

export const MuskResidencePage = () => {
  return (
    <FadePage>
      <Container>
        <Stack spacing='3vh' sx={{ marginBottom: '10vh' }}>
          <h1>Musk Residence</h1>
          <HalfContainer>
            Musk Residence is a conceptual project designed for a celebrity
            entrepreneurial magnate. Situated on a hilltop in Westside Los
            Angeles, the property oversees the expansive skylines of the La La
            Land. The residence reimagines the client’s primary residence as a
            fortress on the hill, manifesting the form and design ethos of the
            client’s enterprises.
          </HalfContainer>
          <img src={aerial} alt='aerial-preview' width='100%' />
          <Typography variant='h3' align='center' fontSize={{ xs: 32, md: 48 }}>
            “Work-Hard, Play-Hard, Rest and Repeat.”
          </Typography>
          <HalfContainer>
            <Box sx={{ px: '5rem' }}>
              <img src={diagramGif} alt='progress-diagram' width='100%' />
            </Box>
            With the goal of creating an ambivalent architectural experience,
            the building is cladded with rigid titanium panels as opposed to the
            curvilinear interiors. The geometrical exterior derives its form
            from the client’s aficionado for futuristic design. Meanwhile, the
            parametric forms in the interior are heavily inspired by luxury
            automobile design.
          </HalfContainer>
          <img src={conference} alt='conference-preview' width='100%' />
          <HalfContainer>
            <img
              src={sectionConference}
              alt='conference-section'
              width='100%'
            />
            The sculptural residence cantilevers over a 30,000 sqft lot,
            injecting a sense of out-worldliness amongst the opulent mansions of
            Bel-Air. The living quarters boast a 1,000 sqft hall, outdoor
            dining, reflection pool, diving pool, and a viewing deck overlooking
            the Los Angeles skyline.
          </HalfContainer>
          <HalfContainer>
            Visitors are dropped off from the cul-de-sac where back of the
            cantilevered conference hall oversees the reception. On the opposite
            side, the conference hall hovers 20 meters above the hill.
            <img src={plan4} alt='level-4-plan' width='100%' />
          </HalfContainer>
          <img src={livingroom} alt='livingroom-preview' width='100%' />
          <HalfContainer>
            <img src={plan2} alt='level-2-plan' width='100%' />
            <div>
              The complex is divided into three quarters for the conference,
              entertainment, and residence. The quarters together emphasize the
              client’s motto: “Work-Hard, Play-Hard, Rest and Repeat.” The grand
              hall is highlighted by the parametric columns that supports the
              reflection pool above, which showers the hall with textured
              daylight.
            </div>
          </HalfContainer>
          <img src={basement} alt='basement-preview' width='100%' />
          <HalfContainer>
            Visitors can enjoy the aquarium view of the diving pool from the
            basement entertainment floor, comprised of a home theatre, and a
            bowling alley.
            <img
              src={sectionLiving}
              alt='living-quarter-section'
              width='100%'
            />
          </HalfContainer>
          <img src={parking} alt='parking-preview' width='100%' />
          <HalfContainer>
            The garage features an elevator designed to transport the visitors
            and their cars to the conference hall. The design alludes to a
            futuristic sci-fi aviation hangar, complemented by the altitude of
            the view angle, to emphasize feelings of launching down/planet-side
            towards the busy metropolitan cityscape of LA below.
            <img src={plan1} alt='level-1-plan' width='100%' />
          </HalfContainer>
          <a href='/' style={{ color: 'black' }}>
            Return home
          </a>
        </Stack>
      </Container>
    </FadePage>
  );
}
