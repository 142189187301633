import { Container, Grid, Stack } from '@mui/material';
import FadePage from '../components/FadePage';
import TitleSection from '../components/TitleSection';

const members = [
  {
    name: 'Bennett Oh',
    creds: ['B. Arch University of Waterloo'],
  },
  {
    name: 'Ji Song Sun',
    creds: ['M. Arch University of Calgary', 'B. Arts University of Toronto'],
  },
  {
    name: 'Vijul Shah',
    creds: [
      'M. Arch University of Calgary',
      'B. Arch Maharaja Sayajirao University of Baroda',
    ],
  },
  {
    name: 'Mark Wang',
    creds: ['M. Arch Harvard University ', 'B. Arch University of Waterloo '],
  },
];

const membersPrev = [
  {
    name: 'Kevin Xu',
    creds: [
      'B. Arts University of Toronto',
      'M. Arch University of Cincinnati',
    ],
  },
  {
    name: 'Megan Wu',
    creds: ['Bachelors of Interior Design BCIT '],
  },
  {
    name: 'Dana Cho',
    creds: ['B. ES University of Waterloo '],
  },
];

export const About = () => {
  return (
    <FadePage>
      <Container>
        <Stack spacing='10vh'>
          {/* spacer */}
          <span></span>
          <h1>Form follows f(x) = architecture + meme</h1>
          <TitleSection
            title=''
            content={
              <Grid container columnSpacing={2}>
                <Grid item sm={12} md={6}>
                  <h3>meme</h3>
                  <p>/mēm/</p>
                  <p>
                    an element of a culture or system of behavior that may be
                    considered to be passed from one individual to another by
                    nongenetic means, especially imitation.
                  </p>
                </Grid>
                <Grid item sm={12} md={6}>
                  <h3>ar·chi·tect</h3>
                  <p>/ˈärkəˌtekt/</p>
                  <p>
                    a professional who is qualified to design and provide advice
                    - both aesthetic and technical - on built objects in our
                    public and private landscapes.
                  </p>
                </Grid>
              </Grid>
            }
          />
          <TitleSection
            title='Who are we?'
            content={
              <>
                <div style={{ marginTop: '1rem' }}>
                  We like to have fun, <strong>seriously</strong>.
                </div>
                <p>
                  Meme Architects is an inter-disciplinary design incubator
                  pursuing innovative and unique aesthetics and an openness to
                  crossing creative borders of various fields with the ultimate
                  goal of bringing the design closer to the public.
                </p>
                <p>
                  Our team is comprised of a passionate group of practicing
                  architects and generalist designers based out of North
                  America.
                </p>
                <Grid container columnSpacing={2}>
                  {members.map((member) => (
                    <Grid item xs={12} sm={6} md={4} key={member.name}>
                      <h4>{member.name}</h4>
                      {member.creds.map((cred) => (
                        <p key={cred}>{cred}</p>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </>
            }
          />
          <TitleSection
            title='Previous collaborators'
            content={
              <>
                <Grid container columnSpacing={2}>
                  {membersPrev.map((member) => (
                    <Grid item xs={12} sm={6} md={4} key={member.name}>
                      <h4>{member.name}</h4>
                      {member.creds.map((cred) => (
                        <p key={cred}>{cred}</p>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </>
            }
          />
          <TitleSection
            title='Career'
            content={
              <>
                <div style={{ marginTop: '1rem' }}>
                  We like to have fun, <strong>seriously</strong>. If you would
                  like to join us in this journey please feel free to{' '}
                </div>
                <a
                  href='mailto:info@memearchitects.com'
                  target='_blank'
                  rel='noreferrer'
                >
                  contact us
                </a>
              </>
            }
          />
        </Stack>
      </Container>
    </FadePage>
  );
};
