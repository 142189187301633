import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import {
  About,
  CliffAndClimbCorpPage,
  Home,
  MuskResidencePage,
  SolarNeoBolognaPage,
  TotemPage
} from './pages';
import Join from './pages/Join';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#fff',
    },
  },
});

function App() {
  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/cliff-and-climb' element={<CliffAndClimbCorpPage />} />
          <Route path='/musk-residence' element={<MuskResidencePage />} />
          <Route path='/solar' element={<SolarNeoBolognaPage />} />
          <Route path='/totem-tower' element={<TotemPage />} />
          <Route path='/contact' element={<Join />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
