import { Fade } from '@mui/material';
import Footer from './Footer';
import Header from './Header';

function FadePage({ isWhite, children }) {
  return (
    <Fade in timeout={2000} style={{ paddingBottom: '4rem', boxSizing: 'border-box' }}>
      <div style={{ position: 'relative', minHeight: '100vh' }}>
        <Header isWhite={isWhite} />
        {children}
        <Footer isWhite={isWhite} />
      </div>
    </Fade>
  );
}

export default FadePage;
