import { Container } from '@mui/material';
import FadePage from '../components/FadePage';

function Join() {
  return (
    <FadePage>
      <Container>
        <script src='https://static.airtable.com/js/embed/embed_snippet_v1.js'></script>
        <iframe
          class='airtable-embed airtable-dynamic-height'
          src='https://airtable.com/embed/shrkB10fs1Ppj3VLw?backgroundColor=yellow'
          title='application-form'
          frameborder='0'
          onmousewheel=''
          width='100%'
          height='1885'
          style={{ background: 'transparent', border: '1px solid #ccc' }}
        ></iframe>
      </Container>
    </FadePage>
  );
}

export default Join;
