import { Container, Stack, Typography } from '@mui/material';
import FadePage from '../components/FadePage';
import HalfContainer from '../components/HalfContainer';
import aperture from '../static/images/cliff-climb-corp/aperture.jpg';
import approach from '../static/images/cliff-climb-corp/approach.jpg';
import axo from '../static/images/cliff-climb-corp/axo.jpg';
import elevation from '../static/images/cliff-climb-corp/elevation.jpg';
import interior from '../static/images/cliff-climb-corp/interior.jpg';
import roof from '../static/images/cliff-climb-corp/roof.jpg';

export const CliffAndClimbCorpPage = () => {
  return (
    <FadePage>
      <Container>
        <Stack spacing='3vh' sx={{ marginBottom: '10vh' }}>
          <h1>Cliff and Climb Corporation HQ</h1>
          <div>
            <Typography>Location: Grand Canyon</Typography>
            <Typography>Area: 10,000 sqft</Typography>
            <Typography>Program - Commercial / Corporate Office</Typography>
          </div>
          <img src={roof} alt='roof-preview' width='100%' />
          <Typography variant='h3' align='center' fontSize={{ xs: 32, md: 48 }}>
            “Adventure - a word that has captivated mankind since the beginning
            of history.”
          </Typography>
          <HalfContainer>
            What does it mean to be adventurous in the modern world that is
            overstimulated by aggressive marketing, innovations, products, or
            fast changing trends? How can a sportswear company realign its
            mission, products, and workspace to the contemporary standard?
          </HalfContainer>
          <img src={approach} alt='approach-preview' width='100%' />
          <HalfContainer>
            Nested in the cliffs of the Grand Canyons, Cliff and Climb
            Corporation’s new headquarter offices are suspended and cantilevered
            off the face of cliffs. The site was chosen with the goal of
            bringing employees closer to their mission and products. This new
            headquarter captures the founder’s appetite for challenge and
            innovation, as well as his motto of making climbing more sustainable
            and accessible to the enthusiasts.
            <img src={elevation} alt='elevation' width='100%' />
          </HalfContainer>
          <HalfContainer>
            The newly imagined HQ comprises modules suspended on the cliffside,
            imitating the precarious alternating climbing holds while causing
            minimum disturbance to the rock faces. The leveled built forms
            express the weightlessness of hanging as they are embedded amongst
            the rocky and treacherous vertical landscape. This unconventional
            typological approach is to accentuate the sense of work space that
            promotes active hiking.
          </HalfContainer>
          <img src={aperture} alt='aperture-preview' width='100%' />
          <HalfContainer>
            The main entrance is located at the top of the cliff to encourage
            employees to trek up to their offices and put them in the shoes of
            their customers. The entrance is a fully glazed elevator that takes
            employees and visitors down to modules suspended below.
          </HalfContainer>
          <img src={interior} alt='interior-preview' width='100%' />
          <HalfContainer>
            In order to fully immerse the inhabitants in the context, interior
            spaces are enclosed by panoramic curtain walls that provide
            270-degrees views of the Grand Canyon, and transparent floors that
            accentuate the verticality.
          </HalfContainer>
          <img src={axo} alt='axo-preview' width='100%' />
          <HalfContainer>
            These seemingly weightless structures are pinned to the cliff face
            by a minimal hinge connection, and lifted with suspension cables.
            This structure leverages the tensile strength of structural steel to
            maximize structure with minimum material. The module’s floor
            construction utilizes steel beams with glulam joists assembly that
            is capped with a large aluminum frame finished with prefabricated
            panels.
          </HalfContainer>
          <a href='/' style={{ color: 'black' }}>
            Return home
          </a>
        </Stack>
      </Container>
    </FadePage>
  );
};
