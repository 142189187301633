import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router';
import FadePage from '../components/FadePage';
import coverImage from '../static/images/totem-tower/ext-overall.jpg';

export const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.5)',
        }}
      />
      <div
        style={{
          height: '100%',
          backgroundImage: `url(${coverImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          // backgroundSize: 'cover',
        }}
      >
        <FadePage isWhite={true}>
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              width: '100%',
              height: '80vh',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
            }}
          >
          <Button
            onClick={() => navigate('/totem-tower')}
            variant='outlined'
            color='secondary'
          >
            Totem Tower
          </Button>
            <Button
              onClick={() => navigate('/cliff-and-climb')}
              variant='outlined'
              color='secondary'
            >
              Cliff and Climb Corporation
            </Button>
            <Button
              onClick={() => navigate('/musk-residence')}
              variant='outlined'
              color='secondary'
            >
              Musk Residence
            </Button>
            <Button
              onClick={() => navigate('/solar')}
              variant='outlined'
              color='secondary'
            >
              Solar Neo-Bologna
            </Button>
          </Box>
        </FadePage>
      </div>
    </>
  );
};
