import { Container } from '@mui/material';

function Footer({ isWhite = false }) {
  const color = isWhite ? 'white' : 'black';
  return (
    <Container sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <span href='/' style={{ color, position: 'absolute', bottom: '25px' }}>
        {'All rights reserved. Copyright © Meme Architects '}
        {new Date().getFullYear()}
      </span>
    </Container>
  );
}

export default Footer;
