import { Container } from '@mui/material';

function Header({ isWhite = false }) {
  const color = isWhite ? 'white' : 'black';
  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <a href='/' style={{ color }}>
        <h1 style={{ color }}>Meme Architects</h1>
      </a>
      <a href='/about' style={{ color }}>
        About
      </a>
    </Container>
  );
}

export default Header;
