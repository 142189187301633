import { Grid } from '@mui/material';

const HalfContainer = ({ children }) => {
  if (typeof children === 'string') {
    children = [children];
  }
  return (
    <Grid
      container
      sx={{
        margin: 0,
        width: '100%',
      }}
      alignItems='center'
    >
      <Grid item sm={12} md={6} sx={{ padding: '8px' }}>
        {children[0] || children}
      </Grid>
      <Grid item sm={12} md={6} sx={{ padding: '8px' }}>
        {children[1]}
      </Grid>
    </Grid>
  );
};

export default HalfContainer;
