import { Container, Stack } from '@mui/material';
import FadePage from '../components/FadePage';
import HalfContainer from '../components/HalfContainer';
import pattern from '../static/images/solar-neo-bologna/1.2a_Pattern Generation.jpg';
import landform from '../static/images/solar-neo-bologna/1.3_Landform Genealogy_Page_1.jpg';
import landform2 from '../static/images/solar-neo-bologna/1.3_Landform Genealogy_Page_2.jpg';
import typologies from '../static/images/solar-neo-bologna/2.2a_Building Typologies.jpg';
import typologiesSelected from '../static/images/solar-neo-bologna/2.2b_Selected Typologies.jpg';
import aerial from '../static/images/solar-neo-bologna/3.1c_Context Aerial Axon.jpg';
import render1 from '../static/images/solar-neo-bologna/3.3c_AtmosphericExperiential Render 1.jpg';
import render2 from '../static/images/solar-neo-bologna/3.3c_AtmosphericExperiential Render 2.jpg';
import render3 from '../static/images/solar-neo-bologna/3.3c_AtmosphericExperiential Render 3.jpg';

export const SolarNeoBolognaPage = () => {
  return (
    <FadePage>
      <Container>
        <Stack spacing='3vh' sx={{ marginBottom: '10vh' }}>
          <h1>Solar Neo-Bologna</h1>
          <HalfContainer>
            The city of Calgary has experienced its two largest floods in 2005
            and 2013. The two events respectively forced more than 40,000 and
            75,000 people to evacuate from their homes, each leaving the city
            with $75 million and $787 million in infrastructural damages and
            recoveries. It is hence more critical than ever to leverage
            architecture and urban design to address some of these recurring
            challenges and reframe Calgary's relationship with water.
            <img src={pattern} alt='pattern-generation-diagram' width='100%' />
          </HalfContainer>
          <img src={landform} alt='landform-overview' width='100%' />
          <img src={landform2} alt='landform-matrix' width='100%' />
          <HalfContainer>
            Applying the values of Solarpunk as foundation, the objective of the
            project “Solar City” is to imagine and design a unique and
            innovative architectural language to create a new urban logic that
            can respond to shifting aqueous ecologies with more agility. The
            project seeks to assist the city of Calgary in cultivating a more
            local bank of alimentary resources.
          </HalfContainer>
          <HalfContainer>
            <img
              src={typologiesSelected}
              alt='livingroom-preview'
              width='100%'
            />
            <img src={typologies} alt='basement-preview' width='100%' />
          </HalfContainer>
          <img src={aerial} alt='aerial-axo-diagram' width='100%' />
          <HalfContainer>
            <img src={render1} alt='level-1-plan' width='100%' />
            <img src={render2} alt='level-2-plan' width='100%' />
          </HalfContainer>
          <img src={render3} alt='living-quarter-section' width='100%' />
          <a href='/' style={{ color: 'black' }}>
            Return home
          </a>
        </Stack>
      </Container>
    </FadePage>
  );
};
